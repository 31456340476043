const slider = document.querySelector(".n2-ss-slider-3");

if (slider != null) {
  var observer = new MutationObserver(function(mutation) {
    const len = mutation.length;
    for (let i = 0; i < len; i++) {
      if (mutation[i].target.classList.contains("n2-ss-slide-active")) {
        const el = document.querySelector(
          ".n2-ss-slide-active .n2-ss-section-main-content"
        );
        el.classList.add("display-slide-content");
      }
    }
  });

  observer.observe(slider, {
    attributeFilter: ["class"],
    attributes: true,
    subtree: true
  });
}
