//https://www.npmjs.com/package/smooth-scroll

import SmoothScroll from "smooth-scroll";

new SmoothScroll('a[href*="#"]', {
  ignore: "[data-vc-container]"
});

const icon = document.querySelector(".go-to-top-link");
document.addEventListener("scroll", () => {
  if (window.scrollY > 200) {
    icon.classList.add("visible");
  } else {
    icon.classList.remove("visible");
  }
});
